import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import ContactForm from "../components/Contact/ContactForm"
import Seo from "../components/App/Seo"
import LexikonBadgesRandomSection from "../components/lexikon/LexikonBadgesRandomSection"

const Kontakt = () => {
  return (
    <Layout>
      <Seo title={"Ihr Kontakt zu uns"}
           description={"Sie haben verschiedene Möglichkeiten uns zu kontaktieren. Wählen Sie selbst" +
           "ob via Formular oder Direkt-Email."}
           image={"/images/og/logentis-kontakt-og.png"}
      />
      <Navbar />
      <ContactForm />
      <section className="testimonials-area bg-fafafb">
        <div className="container pt-70 pb-70">
          <div className="section-title">
            <h3 className={"h3"}>Mehr aus unserem Lexikon</h3>
            <p>Einige Artikel aus der Rubrik "Logistik / Allgemein"</p>
          </div>
          <LexikonBadgesRandomSection product={"Allgemein"}/>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default Kontakt
